<template>
    <v-row class="ma-0 height-100">
      <v-col cols="6">
        <p class="primary--text mb-0">{{$t('BANNER_EDITOR.SIZE')}}</p>
        <div>
          <v-row>
            <v-col cols="3">
              <small>{{$t('BANNER_EDITOR.WIDTH')}}</small>
              <v-text-field type="number" min="10" max="1280" hide-details outlined dense v-model="content.width"/>
            </v-col>
            <v-col cols="1" class="text-center">
              <p class="mt-8">x</p>
            </v-col>
            <v-col cols="3">
              <small>{{$t('BANNER_EDITOR.HEIGHT')}}</small>
              <v-text-field type="number" min="10" max="640" hide-details outlined dense v-model="content.height" />
            </v-col>
            <v-col cols="5">
              <p class="mt-8">pixels</p>
            </v-col>
          </v-row>
        </div>

        <v-divider class="mt-6 mb-6" />

        <div>
          <v-row>
            <v-col cols="6">
              <div>Upload your image:</div>
              <small class="grey--text">.jpg, .jpeg, .png are accepted</small>
            </v-col>
            <v-col cols="6">
              <v-text-field type="hidden" :rules="[$rules.required, $rules.min(1)]" dense v-model="content.image" style="display:none"/>
              <InputImage only-title :formats="image_formats" :value="images" @input="handleImage"/>
            </v-col>
          </v-row>
        </div>
        <v-divider class="mt-6 mb-6" />
        <p>{{$t('BANNER_EDITOR.CONTENT_PARAMS')}}</p>
        <div class="mb-2">
          <v-text-field
          outlined
          hide-details
          dense
          label="Title"
          v-model="content.title"
          :rules="[$rules.required]"
          />
        </div>
        <div class="mb-2">
          <v-text-field
          outlined
          hide-details
          dense
          label="Description"
          v-model="content.description"
          :rules="[$rules.required]"
          />
        </div>
      </v-col>
      <v-divider vertical />
      <v-col cols="6" class="d-flex flex-column align-center grey lighten-3 pt-10">
        <p class="grey--text"><small><i>{{$t('BANNER_EDITOR.BANNER_EXAMPLE')}}</i></small></p>
        <div class="d-inline-block white elevation-4" :style="{width:ratio_width+'px', height:ratio_height+'px', transform:'scale(0.66)', marginBottom: -((content.height-content.height/1.33)/2) + 'px'}">
          <img v-if="Object.keys(images || {}).length"  :src="$getImgSrc(images, 0, '/img/empty-image.png')" :height="ratio_height" :width="ratio_width" />
        </div>
        <div class="text-ellipsis text-nowrap mt-4" :style="{width:ratio_width/1.5+'px', minWidth:'200px'}"><small><strong>{{content.title}}</strong></small></div>
        <div class="text-ellipsis text-nowrap" :style="{width:ratio_width/1.5+'px', minWidth:'200px'}"><small>{{content.description}}</small></div>
      </v-col>
    </v-row>
</template>


<script>
import InputImage from '@/components/InputImage'
export default {
  name:'BannerEditorImage',
  components: {
    InputImage
  },
  props: {
    values: {
      type: [Object],
      default: () => ({})
    }
  },
  created () {
    this.attribValues()
  },
  data: () => ({
    images: {},

    //
    content: {
      width: 200,
      height: 200,
      image: undefined,
      title: '',
      description: '',
    }
  }),

  computed: {
    ratio () {
      return this.content.height<this.content.width
        ? this.content.height/this.content.width
        : this.content.width/this.content.height
    },
    ratio_width () {
      return (this.content.width || 1)
    },
    ratio_height () {
      return (this.content.height || 1)
    },
    image_formats () {
      return [
        {name: 'banner', height: this.content.height || 200, width: this.content.width || 200 },
      ]
    },
  },

  watch: {
    content: {
      handler () {
        this.$emit('input', this.$clone({
          content: this.content,
          params: this.params,
        }))
      },
      deep: true
    }
  },

  methods: {

    putExampleData () {
      this.content = {
        image: undefined,
        title: 'This is a title',
        description: 'And now, a super short description',
      }
    },

    handleImage (images = {}) {
      this.images = images || {}
      if (images && images.banner) {
        this.content.image = images.banner
      }
    },

    attribValues () {
      const values = this.$clone(this.values)
      Object.keys(values).forEach(key => {
        if (key === 'xtramile_occupation_id') {
          for (const occupation of  this.$i18n.t('JOB.XTRAMILE_OCCUPATIONS_ITEMS')) {
            if (values[key] === occupation.value) {
              values[key] = occupation
            }
          }
        }
        if (key === 'date' && typeof values[key] === 'string') values[key] = values[key].slice(0,10)
        if (key === 'content' && values['content'] && values['content']['image']) {
          values.images = {banner:values['content']['image']}
        }
        this[key] = values[key]
      })
    },
  }

}
</script>
